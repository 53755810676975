import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from 'react-router-dom';
import "./App.scss";
import Navbar from "./components/Navbar/Navbar";
const LazyHome = React.lazy(() => import('./components/Home/Home'));
const LazyAboutEunodata = React.lazy(() => import('./components/AboutEunodata/AboutEunodata'));
const LazyCareer = React.lazy(() => import('./components/Career/Career'));
const LazyContactUs = React.lazy(() => import('./components/ContanctUs/ContactUs'));
const LazySolutions = React.lazy(() => import('./components/Solutions/Solutions'));
const LazyUseCases = React.lazy(() => import('./components/UseCases/UseCases'));
const LazyNews = React.lazy(() => import('./components/News/News'));


export default function App() {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
        <Routes>
          <Route path="/" element={<React.Suspense fallback={t("Loading")}><LazyHome /></React.Suspense>} />
          <Route path="/aboutEunodata" element={<React.Suspense fallback={t("Loading")}><LazyAboutEunodata /></React.Suspense>} />
          <Route path="/solutions" element={<React.Suspense fallback={t("Loading")}><LazySolutions /></React.Suspense>} />
          <Route path="/useCases" element={<React.Suspense fallback={t("Loading")}><LazyUseCases /></React.Suspense>} />
          <Route path="/news" element={<React.Suspense fallback={t("Loading")}><LazyNews /></React.Suspense>} />
          <Route path="/career" element={<React.Suspense fallback={t("Loading")}><LazyCareer /></React.Suspense>} />
          <Route path="/contactus" element={<React.Suspense fallback={t("Loading")}><LazyContactUs /></React.Suspense>} />
        </Routes>
        {/* <ContactUsBottom /> */}
      {/* <NavLink to="./ContactUs"><ContactUsBottom /></NavLink> */}
      {/* <Footer /> */}
    </>
  )
}

